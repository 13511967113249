import confetti from 'canvas-confetti';
import { graphql, navigate } from 'gatsby';
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { useCallback, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ButtonComponent from 'ui-kit-v2/button/button';

import LayoutWrapperComponent from 'components/layouts/workflow-v2/layout-wrapper/layout-wrapper';
import WorkflowLayout from 'components/layouts/workflow-v2/workflow.layout';

import { clearRegistrationFormFields } from 'state/registration/registration.reducer';
import { registrationFormValuesSelector } from 'state/registration/registration.selector';

import useCustomerIo from 'hooks/useCustomerIo';
import useRegistration from 'hooks/useRegistration';

import './success.styles.scss';

interface SuccessPageProps {
    data: GatsbyTypes.SuccessPageDataQuery;
}

export const SuccessPage = ({ data }: SuccessPageProps) => {
    useRegistration();
    const { resetCustomerIo } = useCustomerIo();

    const formValues = useSelector(registrationFormValuesSelector);
    const { email } = formValues;

    const { successHandsImage } = data;
    const { t } = useTranslation();

    const shapeOne = confetti.shapeFromPath({
        path: 'M6.19988 20.7228L0.345703 4.62855L19.0791 0.336761L23.7624 15.358L6.19988 20.7228Z'
    });

    const shapeTwo = confetti.shapeFromPath({
        path: 'M5.40449 4.07244C1.0796 7.64102 -0.321866 12.6759 2.27424 15.3181C4.87034 17.9603 10.4809 17.2093 14.8058 13.6407C19.1307 10.0721 20.5322 5.0373 17.9361 2.3951C15.34 -0.247107 9.72938 0.503867 5.40449 4.07244Z'
    });

    const shapeThree = confetti.shapeFromPath({
        path: 'M4.25785 22.8276C11.2829 22.1123 11.6731 20.324 5.42869 17.4628C0.745346 13.8863 1.5259 12.0981 7.77036 12.0981C14.0148 12.0981 14.4051 10.6675 8.9412 7.80629C3.4773 3.5145 4.25785 1.72625 11.2829 2.44155'
    });

    const shapeFour = confetti.shapeFromPath({
        path: 'M14.7665 0.343443L11.5648 10.664L0.634064 14.5091L10.5492 20.2155L9.95323 30.7687L18.5321 24.303L30.2138 28.2387L26.0179 18.2219L33.338 8.7512L22.2416 9.20674L14.7665 0.343443Z'
    });

    const shapeFive = confetti.shapeFromPath({
        path: 'M7.51733 2.44904L2.83398 32.4916'
    });

    const shapeSix = confetti.shapeFromPath({
        path: 'M20.3554 13.832L0.451172 11.6861L2.79285 0.956665L22.6971 3.10256L20.3554 13.832Z'
    });

    const shapeSeven = confetti.shapeFromPath({
        path: 'M23.8376 1.92786C16.0321 3.35845 14.0807 6.93494 17.9835 12.6573C21.8863 19.095 19.9349 23.0292 12.1293 24.4598C5.1043 25.175 3.54319 28.7515 7.44597 35.1892C11.3487 40.9116 9.39736 44.4881 1.5918 45.9187'
    });

    const fireConfetti = useCallback(() => {
        confetti({
            particleCount: 450,
            scalar: 2,
            spread: 200,
            origin: { y: 0, x: 0.5 },
            gravity: 0.8,
            startVelocity: -70,
            shapes: [shapeOne, shapeTwo, shapeThree, shapeFour, shapeFive, shapeSix, shapeSeven],
            colors: ['#B1CCDD', '#222A3F', '#03A4DC']
        });
    }, [shapeOne, shapeTwo, shapeThree, shapeFour, shapeFive, shapeSix, shapeSeven]);

    useEffect(() => {
        if (email) setTimeout(() => fireConfetti(), 500);
    }, [email, fireConfetti]);

    return (
        <WorkflowLayout
            className="setup-your-account-success-page"
            metaData={{ nodeTitle: 'Membership registration' }}
            isUnauthenticatedFlow={true}
        >
            <LayoutWrapperComponent>
                <div className="setup-your-account-success-page__container">
                    <GatsbyImage
                        image={getImage(successHandsImage!) as IGatsbyImageData}
                        alt="Success"
                        className="setup-your-account-success-page__container__image"
                    />
                    <h1 className="setup-your-account-success-page__container__title">
                        {t('registration.success.title')}
                    </h1>
                    <h3 className="setup-your-account-success-page__container__description">
                        {t('registration.success.description_one')}
                    </h3>
                    <h2 className="setup-your-account-success-page__container__description">
                        <Trans i18nKey="registration.success.description_two" values={{ email }} />
                    </h2>
                    <ButtonComponent
                        full
                        onClick={() => {
                            clearRegistrationFormFields();
                            navigate('/sign-in');
                            resetCustomerIo();
                        }}
                        label={t('registration.success.button')}
                        type="button"
                        className="setup-your-account-success-page__container__button"
                    />
                </div>
            </LayoutWrapperComponent>
        </WorkflowLayout>
    );
};

export default SuccessPage;

export const query = graphql`
    query SuccessPageData($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        successHandsImage: file(relativePath: { eq: "assets/images/success-hands-setup-your-account.png" }) {
            id
            childImageSharp {
                gatsbyImageData(formats: PNG, layout: CONSTRAINED, width: 167)
            }
        }
    }
`;
